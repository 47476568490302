<template>
    <section>
        <button class="btn btn-primary" v-b-modal.add-closing-dates>Ajouter</button>

        <!-- Modal d'ajout de fermeture de date -->
        <b-modal id="add-closing-dates" size="lg" title="Ajouter une fermeture de date" hide-footer>
            <div class="card">
                <div class="card-body">
                    <b-form-group label="Fréquence de fermeture" label-for="example-input-1">
                        <select class="custom-select w-auto" v-model="selectedType">
                            <option value="">Sélectionner une fréquence</option>
                            <option value="daily">Journalière</option>
                            <option value="weekly">Hebdomadaire</option>
                        </select>
                        <b-form-invalid-feedback id="input-1-live-feedback">
                            Veuillez sélectionner une fréquence de fermeture
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <hr>

                    <!-- si fermeture journalière -- type == daily -->
                    <div class="row" v-if="selectedType=='daily'">
                        <div class="col-md-12 mb-5 text-center">
                            <h3>Fermeture journalière</h3>
                            <small data-v-f2b16ec4="" class="text-muted">
                                Il s'agit des temps de pause de la journée <br>
                                (Les pauses déjeuner, réunion ...)
                            </small>
                        </div>
                        <div class="col-md-12 responsive-table">
                            
                            <table class="table b-table">
                                <tr class="text-center">
                                    <th>Début de la fermeture</th>
                                    <th>Fin de la fermeture</th>
                                </tr>
                                <tr>
                                    <td>
                                        <b-form-group
                                            id="fieldset-start-hour"
                                            description=""
                                            label-for="input-1"
                                            >
                                            <b-form-timepicker 
                                            class="w-auto"
                                            v-model="daily.start_closing_at"
                                            id="example-input-1"
                                            name="example-input-1"
                                            minutes-step="5"
                                            no-close-button
                                            aria-describedby="input-1-live-feedback"
                                            locale="fr">
                                            </b-form-timepicker>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </td>
                                    <td>
                                        <b-form-group
                                            id="fieldset-end-hour"
                                            description=""
                                            label-for="input-1"
                                            >
                                            <b-form-timepicker 
                                            class="w-auto"
                                            v-model="daily.end_closing_at"
                                            id="end_closing_at"
                                            name="end_closing_at"
                                            minutes-step="5"
                                            no-close-button
                                            aria-describedby="input-1-live-feedback"
                                            locale="fr">
                                            </b-form-timepicker>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>    
                                        </b-form-group>
                                    </td>
                                </tr>
                            </table>
                            <b-form-group
                            class="mt-3"
                                id="fieldset-end-hour"
                                label="Description"
                                description="Titre ou explication de la fermeture"
                                label-for="input-1"
                                >
                                <b-form-input 
                                class="w-auto"
                                v-model="description"
                                placeholder="description"
                                aria-describedby="input-1-live-feedback">
                                </b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                Ce champ est obligatoire.
                                </b-form-invalid-feedback>    
                            </b-form-group>
                        </div>
                        <div class="col-md-12" v-if="daily.start_closing_at && daily.end_closing_at">
                            <!-- phrase de résumée de la fermeture sélectionnée -->
                            <p class="text-right">Tous les jours,
                            <span v-if="description"> pour <b>{{description}}</b>,</span>
                            nous sommes fermés de
                            <b>{{daily.start_closing_at}}</b>
                            jusqu'à
                            <b>{{daily.end_closing_at}}</b>
                        </p>
                        </div>
                    </div>

                    <!-- si fermeture hebdomadaire -- type == weekly -->
                    <div class="row" v-else-if="selectedType=='weekly'">
                        <div class="col-md-12 text-center">
                            <h3>Fermeture Hebdomadaire</h3>
                            <small data-v-f2b16ec4="" class="text-muted">
                                Il s'agit des heures d'ouverture de la société <br>
                            </small>
                        </div>
                        <div class="table-responsive mt-3">
                            <table class="table b-table">
                                <tr>
                                    <th>Status</th>
                                    <th>Jour</th>
                                    <th>Heure d'ouverture</th>
                                    <th>Heure de fermeture</th>
                                </tr>
                                <tr :class="{'stripe-table':day.status=='close'}" v-for="(day,dayId) in weekly" :key="dayId">
                                    <!-- status -->
                                    <td>
                                        <select v-model="day.status" name="status" class="custom-select">
                                            <option value="open">Ouvert</option>
                                            <option value="close">Fermé</option>
                                        </select>
                                    </td>
                                    <!-- Jour -->
                                    <td>
                                        {{day.day}}
                                    </td>
                                    <!-- heure d'ouverture -->
                                    <td class="">
                                        <input class="custom-select" v-if="day.status=='open'" type="time" v-model="day.opening_time">
                                        <span v-else>Fermé</span>
                                    </td>
                                    <!-- heure de fermeture -->
                                    <td>
                                        <input class="custom-select" v-if="day.status=='open'" type="time" v-model="day.closing_time">
                                        <span v-else></span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="row" v-else></div>
                    
                    <div v-if="selectedType">
                        <hr>
                        <div class="d-flex justify-content-end">
                            <button class="btn btn-link mx-3">Annuler</button>
                            <button class="btn btn-success mx-3" @click="postClosingDatesNotification()">Valider</button>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </section>
</template>
<script>
import axios from 'axios'
export default {
    name:"add-closing-dates",
    data(){
        return{
            selectedType:"",
            weekly:[
                {
                    day:"Dimanche",
                    r_day: 0,
                    opening_time: "08:00",
                    closing_time: "17:00",
                    status:"open"
                },
                {
                    day:"Lundi",
                    r_day: 1,
                    opening_time: "08:00",
                    closing_time: "17:00",
                    status:"open"
                },
                {
                    day:"Mardi",
                    r_day: 2,
                    opening_time: "08:00",
                    closing_time: "17:00",
                    status:"open"
                },
                {
                    day:"Mercredi",
                    r_day: 3,
                    opening_time: "08:00",
                    closing_time: "17:00",
                    status:"open"
                },
                {
                    day:"Jeudi",
                    r_day: 4,
                    opening_time: "08:00",
                    closing_time: "17:00",
                    status:"open"
                },
                {
                    day:"Vendredi",
                    r_day: 5,
                    opening_time: "08:00",
                    closing_time: "17:00",
                    status:"open"
                },
                {
                    day:"Samedi",
                    r_day: 6,
                    opening_time: "08:00",
                    closing_time: "17:00",
                    status:"open"
                },
            ],
            daily:{
                start_closing_at:"",
                end_closing_at:"",
            },
            description:"",
            selectedStatus:true
        }
    },
    methods:{
        closingDatesBody(){
            // si fermeture hebdomadaire
            let closing_date_repeating = []
            if(this.selectedType=="weekly") {
                // création des dates de fermeture à partir des dates d'ouverture sélectionnées
                for (let day of this.weekly) {
                    // si la journée entière est fermée
                    if(day.status !== "open") {
                        closing_date_repeating.push({
                            r_day:day.r_day,
                            r_start_hours:"00:00",
                            r_end_hours:"23:59"
                        })
                    }
                    // définition des heures de fermeture du jour
                    else if(day.status == "open" && day.opening_time && day.closing_time) {
                        closing_date_repeating.push(
                            {
                                r_day:day.r_day,
                                r_start_hours:"00:00",
                                r_end_hours:day.opening_time
                            },
                            {
                                r_day:day.r_day,
                                r_start_hours:day.closing_time,
                                r_end_hours:"23:59"
                            })
                    }
                    // ## A METTRE PLUS TARD : STOPPER LA PROPAGATION SI IL Y A UN INPUT TIME VIDE
                    else if(!day.opening_time || !day.closing_time) {
                        alert ('Veuillez mettre les heures sur les journées ouvertes')
                        return
                    }
                }
            }
            // si fermeture journalière
            else if(this.selectedType=="daily"){
                closing_date_repeating.push({
                    r_start_hours:this.daily.start_closing_at,
                    r_end_hours:this.daily.end_closing_at
                })
            }
            let body = {
                "start_closing_at": "2022-06-05T06:55:46+00:00",
                "end_closing_at": "2022-09-05T06:55:46+00:00",
                "description":this.description,
                "repeating": true,
                "type": this.selectedType,
                "companies":localStorage.getItem(`company`),
                "closing_date_repeating": closing_date_repeating
            };
            return body
        },
        postClosingDatesNotification(){
            this.$swal({
                title: "Êtes-vous sûr?",
                text: "Voulez-vous valider cette fermeture?",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui, valider",
                cancelButtonText: "Annuler",
            }).then((result) => {
                if (result.value) {
                this.postClosingDates()
                this.$swal("Ajouté!", "Les horaires de fermeture ont été ajoutés", "success");
                }
            });
        },
        postClosingDates(){
            this.requestClosingDates("post","closing/dates",this.closingDatesBody())
        },
        requestClosingDates(method,url,body){
            this.ploader= true
              axios[method](url, body,
                {
                  headers: {
                    "X-AUTH-TOKEN": localStorage.getItem("token")
                  },
                }
              )
              .then(
                ()=>{
                  this.ploader= false
                }
              )
              .catch(
                err => {
                  this.ploader= false
                  console.error(method,err)
                }
              )
        }
    },
    computed:{
        stripeTable(selectedStatus){
            if(selectedStatus) {
                return "stripe-table"
            }
            return "hello"
        }
    }
}
</script>
<style scoped>
.stripe-table{
     background-color: #f2f2f2;
}
</style>