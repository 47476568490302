<template>
    <section class="closing-dates">
         <div class="page-header">
            <h3 class="page-title" data-aos="fade-left"
            data-aos-anchor="#example-anchor"
            data-aos-offset="500"
            data-aos-duration="2000" >
                <span class="page-title-icon bg-gradient-primary text-white mr-2">
                <i class="mdi mdi-calendar"></i>
                </span> Heures d'ouverture
            </h3>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0);">Compagnie</a></li>
                <li class="breadcrumb-item active" aria-current="page">Heure d'ouverture</li>
                </ol>
            </nav>
        </div>
        
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <h4>Les heures de fermeture</h4>
                            <add-closing-dates />
                        </div>

                        <!-- Tableau des fermetures -->
                        <div class="row">
                            <div class="col-md-12">
                                <div class="responsive-table">
                                    <table class="table b-table">
                                        <tr>
                                            <th>Fréquence</th>
                                            <th>fermeture</th>
                                            <th>Action</th>
                                        </tr>
                                        <tr v-for="day in displayedClosingDates" :key="day.index">
                                            <td>
                                                <span v-if="day.type =='daily'">Journalière</span>
                                                <span v-else-if="day.type =='weekly'">Hebdomadaire</span>
                                            </td>
                                            <td>
                                                {{day.closing_time}}
                                            </td>
                                            <td>
                                                <i class="fa fa-pencil"></i>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <calendarClosingDates />
                        </div>
                        <!-- <button @click="repetitiveClosingDates()">TEST</button>
                        <button @click="testType()">TEST2</button> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import addClosingDates from '../../../components/functions/add-closing-dates.vue'
import calendarClosingDates from '../../../components/functions/calendar-closing-dates.vue'
import { dateMixin } from '../mixins/date'
export default {
    name:"closing-dates",
    mixins:[dateMixin],
    components:{
        addClosingDates,
        calendarClosingDates
    },
    data(){
        return{
            closingDates:[],
            displayedClosingDates:[]
        }
    },
    methods:{
        getClosingDates(){
            axios.get('closing/dates')
            .then(resGetClosingDates => {
                let closingDates = resGetClosingDates.data.result
                this.closingDates = closingDates.filter(date => date.repeating)
                this.repetitiveClosingDates()
            })
            .catch(errGetClosingDates => {
                errGetClosingDates
            })
        },
        repetitiveClosingDates(){
            for(let date of this.closingDates){
                let closing_time = []
                if(date.type == "daily") {
                    for (let time of date.closing_date_repeating) {
                        closing_time.push(this.stringToHourMinute(time.r_start_hours)+" à "+ this.stringToHourMinute(time.r_end_hours)) 
                    }
                }
                if(date.type == "weekly") {
                    closing_time.push(this.filterClosedDaysInWeek(date.closing_date_repeating))
                }
                this.displayedClosingDates.push({
                    ulid:date.ulid,
                    type:date.type,
                    closing_time:closing_time.toString()
                })
            }
        },
        // filtre en jour les dates fermées de la semaine
        filterClosedDaysInWeek(closing_date_repeating){
            let r_days = []
            // r_days == tableau contenant les jours de la semaine
            for(let day of closing_date_repeating){
                 r_days.push(day.r_day)
            }
            // filtrer les jours fermés de la semaine
            r_days = r_days.filter(day => r_days.indexOf(day)<=1)
            let days = ["Dimanche","Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"]
            let string_days = []
            // traduire les index des jours par leur nom(string)
            for ( let day of r_days) {
                string_days.push(days[day])
            }
            return string_days
        }
    },
    async mounted(){
        this.getClosingDates()
    }
}
</script>