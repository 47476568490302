<template>
    <section>
        <div class="row d-flex justify-content-center">
            <div v-if="loader">
                <div class="jumping-dots-loader "><span></span><span></span><span></span></div>
                <span>En cours de chargement</span> 
            </div>
            <div v-else class="col-md-12 time">
                <VueCal
                :key="vueCalKey"
                :style="eventsStyle+' '+ 'vuecal__event'"
                :selected-date="dateString()"
                :events="events"
                :on-event-click="eventInfo"
                locale="fr"
                >
                <!-- :on-event-dblclick="onEventClick" //paramètre dans VueCal -->
                    <template v-slot:event="{ event }">
                        <!-- <div class="event-content" :style="{ backgroundColor: event.backgroundColor, color: event.color }"> -->
                        <div class="event-content" :style="{ background: event.background, color: event.color }">
                            <div class="vuecal__event-title vuecal_title" v-html="event.title"></div>
                            <!-- <hr /><em class="vuecal__event-time"><span> De {{ event.start.formatTime() }}</span> <strong>à</strong> <span>{{ event.end.formatTime() }}</span></em> -->
                        </div>
                    </template>
                </VueCal>
            </div>
        </div>
        <b-modal id="modal"  hide-footer :title="selected2.title">
            <div class="card">
                <div class="card-body" >
                    <h3>Date début</h3>
                    <p>{{fullDateFr(selected2.start)}}</p>
                    <h3>Date Fin</h3>
                    <p>{{fullDateFr(selected2.end)}}</p>
                </div>
                <hr>
                <div class="d-flex justify-content-end my-3">

                    <button class="btn btn-link" @click="$bvModal.hide('modal')">Annuler</button>
                    <button v-if="selected2.title=='FERMETURE - fermeture de la boutique'" class="btn btn-danger mx-4" @click="deleteClosingDate(selected2.ulidPurchases)" title="Cette action effacera cette fermeture de date">Supprimer</button>
                    <button v-else class="btn btn-primary mx-4" @click="onEventClick(selected2)">Voir les détails</button>
                </div>
                <div v-if="isload" class="jumping-dots-loader">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </b-modal>
    </section>
</template>
<script>


import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
// import 'vue-cal/dist/i18n/fr.js'

import axios from 'axios';
import { dateMixin } from '@/pages/dashboard/mixins/date.js'
export default {
    name:'full-time-picker-meb',

    mixins:[dateMixin],
    components:{
        VueCal,
        // mapState
    },
    data(){
        return {
            schedules:[],
            events: [],
            loading: true,
            disabled:"years",
            selected:{},
            loader:true,
            selected2:{
                start: new Date(),
                end: new Date(),
                ulid:'',
                ulidPurchases:''
            },
            isload:false,
            vueCalKey:111
        }
    },
    computed:{
        eventsStyle(){
            return "background-color: red"
        },
        disableViews(){
            if(this.$device.windows){
                return ['years','year']
            }else{
                return ['years','year','month','week']
            }
        }
    },
    methods:{
        dateString(mydate){
            var stringdate;

            if (!mydate) {
                mydate = new Date();    
            }
            stringdate =  mydate.getFullYear() + "-"
                + this.digit((mydate.getMonth()+1))  + "-" 
                + this.digit(mydate.getDate()) + " "

                + this.digit(mydate.getHours()) + ":"  
                + this.digit(mydate.getMinutes()) + ":" 
                + this.digit(mydate.getSeconds())

            return stringdate
        },
        digit(time){
            if (Number(time)< 10) {
                time = "0"+time
            }
            return time
        },
      getSchedules(){
        axios.get('closing/schedules')
        .then(async resGetSchedules => {
            let schedules= await resGetSchedules.data
            this.scheduled(schedules)
            // this.createEvent(this.schedules)
        })
        .catch(errGetSchedules => errGetSchedules)
      },
     async scheduled(schedules){
        for(let event of schedules) {
                await this.events.push({
                    start:this.formatDate(event.datestart),
                    end:this.formatDate(event.dateend),
                    title:'indisponible',
                    class:'unavailable'
                })
            }
            console.log("scheduled farany");
            this.loader=false
      },
       createEvent(reservations){
        //   let hashColor ="repeating-linear-gradient(45deg, transparent, transparent 10px, #f2f2f2 10px, #f2f2f2 20px)"
        //   let ordorUnique = ""
          let bgColor ="#"+(Math.floor(Math.random()*16777215).toString(16))
          let color = '#fff'
        //   parcourir toutes les réservations
        for(let data of reservations) {
                console.log("data",data.appointments)
                data.appointments.forEach(element => {
                    this.events.push({
                      start: this.formatDate(element.start_hour),
                      end: this.formatDate(element.end_hour),
                      title:element.service.name +' - '+"test",
                      // title:this.getServiceName(element.service.ulid)+' - '+element.order.deliveryinfos.name,
                      color: color,
                      background: bgColor,
                      ulid:data.ulid,
                      ulidPurchases:element.ulid
                    })
                })
        }
        this.loader = false // solution de secours
      },
      onEventClick(event){
        console.log(event);
      },
      eventInfo(event, e){
        //   console.log('event',event);
        //   console.log('e',e);
          // Prevent navigating to narrower view (default vue-cal behavior).
          e.stopPropagation()
          this.selected2 = event
          this.$bvModal.show('modal')
      },
      ordersDetails(ulid){
          this.$router.push({
              name:"ordersDetails",
              params:{
                  ulid:ulid
              }
          })
      },
      keyCalendar(){
          this.$emit('keyCalendar', this.vueCalKey++)
      }
    },
    mounted(){
    //   this.getService()
    this.getSchedules()
      this.$device
    }
}
</script>
<style scoped>
:root{
--randomColor:
}
.randomColor{
    background-color: --randomcolor;
}
.time{
    min-height: 50rem;
}
::v-deep .orange{
    background: #cda525;
    color: #fff;
}
::v-deep .closed{
    background: #ffa4a4;
    color: #fff;
}
.vuecal_title{
    text-shadow: 4px 3px 9px rgba(0,0,0,0.6);
    /* text-shadow: 3px 0px 2px rgba(0,0,0,0.76); */
}
.event-content{
    height: 99%;
    border-radius: 4px;
    padding: 4px;
}
::v-deep .unavailable{
    background: #cda525;
    color: #fff;
}
</style>